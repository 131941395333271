import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../services/auth.service';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../../environments/environment';
import { Router, RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
@Component({
    selector: 'app-user-avatar-button',
    imports: [
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        MatDividerModule,
    ],
    templateUrl: './user-avatar-button.component.html',
    styleUrl: './user-avatar-button.component.scss'
})
export class UserAvatarButtonComponent implements OnInit {
  constructor(public authService: AuthService, private platform: Platform) {}

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }
    this.authService.getSelf();
  }

  async logout() {
    try {
      await this.authService.logout();
      location.reload();
    } catch (error) {}
  }
}
