import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-not-found-page',
    imports: [RouterModule, MatButtonModule],
    templateUrl: './not-found-page.component.html',
    styleUrl: './not-found-page.component.scss'
})
export class NotFoundPageComponent {
  constructor(private titleService: TitleService) {
    this.titleService.set('Not Found');
  }
}
