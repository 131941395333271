import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
declare let grecaptcha: any;
@Component({
    selector: 'app-google-recaptcha',
    imports: [],
    templateUrl: './google-recaptcha.component.html',
    styleUrl: './google-recaptcha.component.scss'
})
export class GoogleRecaptchaComponent implements AfterViewInit {
  @ViewChild('captcha') captchaEl!: ElementRef;

  constructor(private platform: Platform) {}

  ngAfterViewInit(): void {
    if (
      !this.captchaEl ||
      !this.captchaEl.nativeElement ||
      !this.platform.isBrowser
    ) {
      return;
    }
    grecaptcha.ready(() => {
      grecaptcha.render(this.captchaEl.nativeElement, {
        sitekey: environment.google.reCaptcha.siteKey,
      });
    });
  }
}
