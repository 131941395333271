import { Component } from '@angular/core';
import { TitleService } from '../../../services/title.service';

@Component({
    selector: 'app-privacy-policy-page',
    imports: [],
    templateUrl: './privacy-policy-page.component.html',
    styleUrl: './privacy-policy-page.component.scss'
})
export class PrivacyPolicyPageComponent {
  constructor(private title: TitleService) {
    this.title.set('Privacy Policy');
  }
}
