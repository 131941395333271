import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ContactEmailButtonComponent } from '../../components/common/contact-email-button/contact-email-button.component';
import { ContactWhatsappButtonComponent } from '../../components/common/contact-whatsapp-button/contact-whatsapp-button.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-about-page',
  imports: [
    MatButtonModule,
    MatIconModule,
    ContactEmailButtonComponent,
    ContactWhatsappButtonComponent,
  ],
  templateUrl: './about-page.component.html',
  styleUrl: './about-page.component.scss',
  animations: [
    trigger('showup', [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          animate(
            '{{duration}}ms {{delay}}ms ease-in-out',
            style({ opacity: 1, transform: 'translateY(0px)' })
          ),
        ],
        { params: { duration: 500, delay: 250 } }
      ),
    ]),
  ],
})
export class AboutPageComponent {
  animationParams1 = { value: ':enter', params: { duration: 500, delay: 200 } };
  animationParams2 = { value: ':enter', params: { duration: 500, delay: 400 } };
  animationParams3 = { value: ':enter', params: { duration: 500, delay: 600 } };
  animationParams4 = {
    value: ':enter',
    params: { duration: 500, delay: 800 },
  };
  animationParams5 = {
    value: ':enter',
    params: { duration: 500, delay: 1000 },
  };
  constructor(private title: TitleService) {
    this.title.set('About');
  }
}
