export const environment = {
  isDevelopment: false,
  contact: {
    whatsapp: '8613958402194',
    email: 'alice@superapparels.com',
  },
  google: {
    reCaptcha: {
      siteKey: '6LfBzzcqAAAAAGDxiJXlK2bw5T0W1m8m0gpEDIMS',
    },
    oauth: {
      clientID:
        '10846546863-9cs4en73v3thtibcbdu44f6i06sh52nc.apps.googleusercontent.com',
    },
  },
  api: {
    host: 'https://superapparel.online/api',
  },
};
