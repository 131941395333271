import { Component, effect, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { validateFormGroup } from '../../../utils/form';
import { UserApiService } from '../../../services/api/user-api.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-update-account-email-dialog',
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './update-account-email-dialog.component.html',
    styleUrl: './update-account-email-dialog.component.scss'
})
export class UpdateAccountEmailDialogComponent implements OnInit {
  email = '';
  formGroup: FormGroup;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<UpdateAccountEmailDialogComponent>
  ) {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    effect(() => {
      const email = this.authService.self()?.email ?? '';
      this.formGroup.patchValue({ email });
    });
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  ngOnInit(): void {
    this.authService.getSelf();
  }

  onUpdate() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    this.updateAccountEmail(values.email);
  }

  loading = false;
  async updateAccountEmail(email: string) {
    try {
      this.loading = true;
      const r = await this.userApi.updateAccountEmail({ email });
      if (r.status === ApiStatus.ErrUserEmailDuplicated) {
        this.messageService.open(
          'This email has been used by another account',
          'OK'
        );
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.messageService.open('Email Updated', 'OK');
        this.authService.self.set(r.data);
        this.close();
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
