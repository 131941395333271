import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-contact-whatsapp-button',
    imports: [MatIconModule, MatButtonModule],
    templateUrl: './contact-whatsapp-button.component.html',
    styleUrl: './contact-whatsapp-button.component.scss'
})
export class ContactWhatsappButtonComponent {
  get whatsapp() {
    return environment.contact.whatsapp;
  }
}
