import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TitleService } from '../../../services/title.service';
import { ProductApiService } from '../../../services/api/product-api.service';
import { ProductImagesSwiperComponent } from '../../../components/product/product-images-swiper/product-images-swiper.component';
import { MatDividerModule } from '@angular/material/divider';
import { DatePipe, Location, NgClass, NgOptimizedImage } from '@angular/common';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HttpParams } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { animate, style, transition, trigger } from '@angular/animations';
import { ProductAttrValueItemComponent } from '../../../components/product/product-attr-value-item/product-attr-value-item.component';
import { MatRippleModule } from '@angular/material/core';
import { ProductPricedAttrValueItemComponent } from '../../../components/product/product-priced-attr-value-item/product-priced-attr-value-item.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { CartService } from '../../../services/cart.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';
import { QuantityInputComponent } from '../../../components/common/quantity-input/quantity-input.component';

@Component({
    selector: 'app-shopping-product-page',
    imports: [
        ProductImagesSwiperComponent,
        MatDividerModule,
        NgClass,
        MatButtonToggleModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinner,
        ProductAttrValueItemComponent,
        MatRippleModule,
        ProductPricedAttrValueItemComponent,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatTooltipModule,
        RouterModule,
        MatProgressSpinnerModule,
        DatePipe,
        QuantityInputComponent,
    ],
    templateUrl: './shopping-product-page.component.html',
    styleUrl: './shopping-product-page.component.scss',
    animations: [
        trigger('content', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('.5s ease-in-out', style({ opacity: 1 })),
            ]),
        ]),
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic',
            },
        },
    ]
})
export class ShoppingProductPageComponent {
  no = '';
  queryAttrs: any = null;
  quantity = 1;
  constructor(
    private route: ActivatedRoute,
    private title: TitleService,
    private productApi: ProductApiService,
    private location: Location,
    private cartService: CartService,
    private messageService: MessageService
  ) {
    this.title.set('Product');
    this.route.params.subscribe((r) => {
      this.no = r['id'];
      this.selectedAttr = {};
      this.getProductByNO();
    });
    this.route.queryParams.subscribe((r) => {
      this.queryAttrs = r['attrs'] || [];
      this.quantity = parseInt(r['q']) || 1;
      if (this.quantity <= 0) {
        this.quantity = 1;
      }
      this.loadAttrs();
    });
  }

  loading = false;
  data: any = null;
  selectedAttr: any = {};

  async getProductByNO() {
    this.data = null;
    try {
      this.loading = true;
      const r = await this.productApi.getProductByNO(this.no);
      this.data = r.data;
      if (this.data) {
        this.title.set(this.data.name);
        this.loadAttrs();
      } else {
        this.title.set('Not Found');
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  loadAttrs() {
    let attrs = this.queryAttrs;
    if (!this.data) {
      return;
    }
    if (!attrs) {
      attrs = [];
    } else if (!Array.isArray(attrs)) {
      attrs = [attrs];
    }
    for (const attr of attrs) {
      const id = parseInt(attr);
      if (!id) {
        continue;
      }
      for (const attr of this.data.attrs) {
        for (const value of attr.values) {
          if (value.id === id) {
            this.selectedAttr[attr.id] = id;
            break;
          }
        }
      }
      for (const value of this.data.priced_attr.values) {
        if (value.id === id) {
          this.selectedAttr[this.data.priced_attr.id] = id;
          break;
        }
      }
    }
    // 载入默认的选择，第一个值
    for (const attr of this.data.attrs) {
      if (!this.selectedAttr[attr.id] && attr.values.length > 0) {
        this.selectedAttr[attr.id] = attr.values[0].id;
      }
    }
    if (
      !this.selectedAttr[this.data.priced_attr.id] &&
      this.data.priced_attr.values.length > 0
    ) {
      this.selectedAttr[this.data.priced_attr.id] =
        this.data.priced_attr.values[0].id;
    }
  }

  selectAttrValue(attr: any, id: number) {
    this.selectedAttr[attr.id] = id;
    this.updateParams();
  }

  updateParams() {
    const ids: number[] = [];
    for (const key in this.selectedAttr) {
      if (Object.prototype.hasOwnProperty.call(this.selectedAttr, key)) {
        const element = this.selectedAttr[key];
        ids.push(element);
      }
    }
    const params = new HttpParams().appendAll({
      attrs: ids,
      q: this.quantity,
    });
    this.location.replaceState(location.pathname, params.toString());
  }

  get subtotal() {
    if (!this.data || !this.data.priced_attr) {
      return 0;
    }
    let price = 0;
    for (const value of this.data.priced_attr.values) {
      if (value.quantity) {
        price += value.price * value.quantity;
      }
    }
    return Math.round(price * 10) / 10;
  }

  cartLoading = false;

  async addToCart() {
    if (this.cartLoading) {
      return;
    }
    const attrValueIDs: number[] = [];
    for (const attr of this.data.attrs) {
      if (!this.selectedAttr[attr.id]) {
        this.messageService.open(`Please choose a ${attr.name}`, 'OK');
        return;
      }
      attrValueIDs.push(this.selectedAttr[attr.id]);
    }

    const items: {
      product_id: number;
      attr_value_ids: number[];
      priced_attr_value_id: number;
      quantity: number;
    }[] = [];
    for (const value of this.data.priced_attr.values) {
      if (value.quantity) {
        items.push({
          product_id: this.data.id,
          attr_value_ids: attrValueIDs,
          priced_attr_value_id: value.id,
          quantity: value.quantity,
        });
      }
    }
    if (items.length === 0) {
      // this.messageService.open(
      //   `Please choose a "${this.data.priced_attr.name}"`,
      //   'OK'
      // );
      return;
    }

    try {
      this.cartLoading = true;
      const r = await this.cartService.addShoppingCartItems(items);
      if (r.status === ApiStatus.ErrProductAttrInvalid) {
        this.messageService.open(
          `Invalid product, please refresh the page.`,
          'OK'
        );
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open(`Unknown error`, 'OK');
      } else {
        this.messageService
          .open('Added to the cart', 'View Cart')
          ?.onAction()
          .subscribe(() => {
            this.cartService.toggleDrawer();
          });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.cartLoading = false;
      // this.cartService.findShoppingCartItems();
    }
  }
}
