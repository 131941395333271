import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';
import { MatExpansionModule } from '@angular/material/expansion';
export interface FAQ {
  question: string;
  answer: string;
}

@Component({
    selector: 'app-faq-page',
    imports: [MatExpansionModule],
    templateUrl: './faq-page.component.html',
    styleUrl: './faq-page.component.scss'
})
export class FaqPageComponent {
  faqs: FAQ[] = [
    {
      question: 'Do you have a Minimum Order Quantity (MOQ)?',
      answer:
        'No MOQ for RTS (Ready To Ship) products. For OEM products, the MOQ is 100-200 pieces per color, depending on the fabric and craftsmanship.',
    },
    {
      question: 'Do you provide samples?',
      answer:
        'Yes, we provide samples free of charge, but the shipping cost will apply. For long-term customers, we waive both the sample and shipping fees.',
    },
    {
      question: 'Can I customize the logo?',
      answer:
        'Yes, logo customization is available for a small fee. Please contact us for more details.',
    },
    {
      question: 'Do you offer customization?',
      answer:
        'Yes, we offer customization. You can send us your design or images, and we can create samples, produce, and ship based on them. Our quality can achieve 90-95% similarity to the original image. We guarantee your design will remain exclusive to you and not be shared with other customers.',
    },
    {
      question: 'What is the OEM production time?',
      answer:
        "For custom products, it usually takes about 30 days to make them after we get the deposit. This doesn't include the shipping time.",
    },
    {
      question: 'Which regions do you ship to?',
      answer:
        'We primarily serve the U.S. market, but we can ship worldwide. Shipping to the U.S. can be done by express, air, or sea. Express takes 3 days, air freight takes 15 days, and sea freight takes around 23 days for delivery.',
    },
    {
      question: 'Can I track my package?',
      answer:
        'After shipment, we will provide you with a tracking number, which you can use to check the status of your package on the corresponding shipping platform.',
    },
    {
      question: 'Are your prices inclusive of shipping costs?',
      answer:
        'Prices do not include shipping costs, which vary based on your location, product type, and quantity. If you would like to know the specific shipping cost for a product, feel free to contact us.',
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept T/T, PayPal, and Alibaba Payment.',
    },
    {
      question: 'What is your after-sales guarantee?',
      answer:
        'If there is a quality issue, we offer a refund within 30 days upon proof of the defect.',
    },
  ];

  constructor(private title: TitleService) {
    this.title.set('Frequently Asked Questions');
  }
}
