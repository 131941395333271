import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UserOrderStatus } from '../../../services/api/status';

@Component({
    selector: 'app-shopping-order-status',
    imports: [NgClass],
    templateUrl: './shopping-order-status.component.html',
    styleUrl: './shopping-order-status.component.scss'
})
export class ShoppingOrderStatusComponent {
  @Input() status = '';

  get text(): string {
    switch (this.status) {
      case UserOrderStatus.UserOrderStatusPending:
        return 'Under Review';
      case UserOrderStatus.UserOrderStatusApproved:
        return 'Approved';
      case UserOrderStatus.UserOrderStatusRejected:
        return 'Rejected';
      case UserOrderStatus.UserOrderStatusPaid:
        return 'Paid';
      case UserOrderStatus.UserOrderStatusCancelled:
        return 'Cancelled';
      case UserOrderStatus.UserOrderStatusPartiallyShipped:
        return 'Partially Shipped';
      case UserOrderStatus.UserOrderStatusShipped:
        return 'Shipped';
      case UserOrderStatus.UserOrderStatusCompleted:
        return 'Completed';
    }
    return 'Unknown';
  }
}
