import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-contact-email-button',
    imports: [MatIconModule, MatButtonModule],
    templateUrl: './contact-email-button.component.html',
    styleUrl: './contact-email-button.component.scss'
})
export class ContactEmailButtonComponent {
  get email() {
    return environment.contact.email;
  }
}
