import { Injectable, signal } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ShoppingApiService } from './api/shopping-api.service';
import { ApiStatus } from './api/status';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  items = signal<any[]>([]);
  groupedItems = signal<any[]>([]);
  quantity = signal<number>(0);
  originalPrice = signal<number>(0);
  price = signal<number>(0);
  private drawer?: MatDrawer;
  constructor(private shoppingApi: ShoppingApiService) {}

  setDrawer(d: MatDrawer) {
    this.drawer = d;
  }
  toggleDrawer() {
    this.drawer?.toggle();
  }

  async findShoppingCartItems() {
    try {
      const r = await this.shoppingApi.findShoppingCartItems();
      this.items.set(r.data || []);
    } catch (error) {
    } finally {
      this.sum();
    }
  }

  async updateShoppingCartItem(id: number, quantity: number) {
    const r = await this.shoppingApi.updateUserShoppingCartItem(id, quantity);
    if (r.status === ApiStatus.OK) {
      if (!r.data) {
        // 删除
        const items: any[] = [];
        for (const item of this.items()) {
          if (item.id !== id) {
            items.push(item);
          }
        }
        this.items.set(items);
      } else {
        const items: any[] = [];
        for (const item of this.items()) {
          if (item.id === r.data.id) {
            item.quantity = r.data.quantity;
          }
          items.push(item);
        }
        this.items.set(items);
      }
      this.sum();
    }

    return r;
  }

  async deleteUserShoppingCartItem(id: number) {
    const r = await this.shoppingApi.deleteUserShoppingCartItem(id);
    if (r.status === ApiStatus.OK) {
      const items: any[] = [];
      for (const item of this.items()) {
        if (item.id !== id) {
          items.push(item);
        }
      }
      this.items.set(items);
      this.sum();
    }
    return r;
  }

  async addShoppingCartItem(
    productID: number,
    attrValueIDs: number[],
    pricedAttrValueID: number,
    quantity: number
  ) {
    const r = await this.shoppingApi.addShoppingCartItem(
      productID,
      attrValueIDs,
      pricedAttrValueID,
      quantity
    );
    if (r.status === ApiStatus.OK) {
      const items = this.items();
      const item = r.data;
      let matched = false;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === item.id) {
          items[i].quantity = item.quantity;
          this.items.set(items);
          matched = true;
          break;
        }
      }
      if (!matched) {
        items.push(item);
        this.items.set(items);
      }
      this.sum();
    }
    return r;
  }

  async addShoppingCartItems(
    items: {
      product_id: number;
      attr_value_ids: number[];
      priced_attr_value_id: number;
      quantity: number;
    }[]
  ) {
    const r = await this.shoppingApi.addShoppingCartItems(items);
    if (r.status === ApiStatus.OK) {
      const items = this.items();
      for (const item of r.data) {
        let matched = false;
        for (let i = 0; i < items.length; i++) {
          if (items[i].id === item.id) {
            items[i].quantity = item.quantity;
            matched = true;
            break;
          }
        }
        if (!matched) {
          items.push(item);
        }
      }
      this.items.set(items);
      this.sum();
    }
    return r;
  }

  sum() {
    let q = 0;
    let p = 0;
    let op = 0;
    const groupedItems: any[] = [];
    for (const item of this.items()) {
      if (item.product.status === 'Online') {
        q += item.quantity;
        p += item.quantity * (item.price || item.priced_attr_value.price);
        op += item.quantity * item.priced_attr_value.price;
      }
      let matched = false;
      for (const item0 of groupedItems) {
        if (item0.product.no === item.product.no) {
          matched = true;
          item0.extra = item0.extra || [];
          item0.extra.push(Object.assign({}, item));
          break;
        }
      }
      if (!matched) {
        groupedItems.push(Object.assign({ extra: [] }, item));
      }
    }
    this.groupedItems.set(groupedItems);
    this.quantity.set(q);
    this.price.set(p);
    this.originalPrice.set(op);
  }
}
