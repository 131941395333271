import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-shopping-payment-panel',
    imports: [MatExpansionModule, MatListModule, MatIconModule, NgOptimizedImage],
    templateUrl: './shopping-payment-panel.component.html',
    styleUrl: './shopping-payment-panel.component.scss'
})
export class ShoppingPaymentPanelComponent {}
