<div class="page-content">
  <form [formGroup]="formGroup" (ngSubmit)="submit($event)" autocomplete="off">
    <h1>Login</h1>
    <h2>Hi, Welcome back 👋</h2>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="email"
        (ngModelChange)="formGroup.get('email')?.setErrors(null)"
      />
      <mat-icon matPrefix>mail</mat-icon>
      <mat-error>Please enter a valid email.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        placeholder=""
        type="password"
        formControlName="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-error>Please enter a password.</mat-error>
    </mat-form-field>

    <div class="actions">
      <mat-checkbox formControlName="remember">Remember Me</mat-checkbox>
      <a (click)="forgotPassword()" aria-label="Forgot Password"
        >Forgot Password?</a
      >
    </div>

    <button mat-flat-button>
      @if (loading) {
      <mat-spinner [diameter]="30"></mat-spinner>
      }@else {
      <span>Login</span>
      }
    </button>

    <div class="divider">
      <mat-divider></mat-divider>
      <span>Or</span>
      <mat-divider></mat-divider>
    </div>

    <a id="google-signin-btn" matRipple aria-label="Continue with Google">
      <img
        ngSrc="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
        width="24"
        height="24"
        alt="google"
      />
      <span>Continue with Google</span>
    </a>

    <div class="signup">
      <span>Not registered yet?</span>
      <a routerLink="/signup" aria-label="Signup">Create an account</a>
    </div>
  </form>
</div>
