import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { validateFormGroup, validateNonEmptyString } from '../../../utils/form';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe } from '@angular/common';
import { map, Observable, startWith } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { UserApiService } from '../../../services/api/user-api.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
    selector: 'app-update-user-address-dialog',
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        AsyncPipe,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './update-user-address-dialog.component.html',
    styleUrl: './update-user-address-dialog.component.scss'
})
export class UpdateUserAddressDialogComponent {
  formGroup: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<UpdateUserAddressDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApi: UserApiService,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      fname: [data?.fname || '', [Validators.required, validateNonEmptyString]],
      lname: [data?.lname || '', [Validators.required, validateNonEmptyString]],
      address: [
        data?.address || '',
        [Validators.required, validateNonEmptyString],
      ],
      country: [
        data?.country || '',
        [Validators.required, validateNonEmptyString],
      ],
      zipcode: [
        data?.zipcode || '',
        [Validators.required, validateNonEmptyString],
      ],
      phone: [data?.phone || '', [Validators.required, validateNonEmptyString]],
      company: [data?.company || '', []],
    });

    this.countryFilteredOptions = this.formGroup.controls[
      'country'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCountry(value))
    );
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  countryOptions: string[] = [
    'United States',
    'Canada',
    'Australia',
    'New Zealand',
    'Malaysia',
    'Thailand',
    'United Kingdom',
    'France',
    'Germany',
    'Italy',
    'Spain',
    'Netherlands',
    'Sweden',
    'Norway',
    'Denmark',
    'Finland',
    'Belgium',
    'Switzerland',
    'Austria',
    'Mexico',
  ];
  countryFilteredOptions: Observable<string[]>;

  private _filterCountry(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countryOptions.filter((value) =>
      value.toLowerCase().includes(filterValue)
    );
  }

  onOk() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    if (!this.data?.id) {
      this.createUserAddress(values);
    } else {
      this.updateUserAddress(this.data.id, values);
    }
  }

  loading = false;
  async createUserAddress(values: any) {
    try {
      this.loading = true;
      const r = await this.userApi.createUserAddress(values);
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.close(r.data);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async updateUserAddress(id: number, values: any) {
    try {
      this.loading = true;
      const r = await this.userApi.updateUserAddress(id, values);
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.close(r.data);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async deleteUserAddress() {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      const r = await this.userApi.deleteUserAddress(this.data.id);
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.close(false);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
