import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface InfoAction {
  text: string;
  onClick: () => void;
}

export interface InfoData {
  title: string;
  messages: string[];
  actions?: InfoAction[];
}

@Component({
    selector: 'app-info-dialog',
    imports: [MatDialogModule, MatButtonModule, MatIconModule],
    templateUrl: './info-dialog.component.html',
    styleUrl: './info-dialog.component.scss'
})
export class InfoDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoData
  ) {}
}
