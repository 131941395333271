import { Component } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { validateFormGroup, validateNonEmptyString } from '../../../utils/form';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserApiService } from '../../../services/api/user-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';

@Component({
    selector: 'app-password-reset-page',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './password-reset-page.component.html',
    styleUrl: './password-reset-page.component.scss'
})
export class PasswordResetPageComponent {
  formGroup: FormGroup;
  id = '';
  token = '';
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router
  ) {
    this.titleService.set('Reset Password');
    this.route.queryParams.subscribe((r) => {
      this.id = r['id'];
      this.token = r['token'];
    });

    this.formGroup = this.fb.group({
      password: [
        '',
        [Validators.required, Validators.pattern(`^(?=.*[A-Z]).{8,}$`)],
      ],
      password2: ['', [Validators.required, validateNonEmptyString]],
    });
  }

  loading = false;

  submit() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    const password = values.password;
    const password2 = values.password2;
    if (password !== password2) {
      this.formGroup.controls['password2'].setErrors({ invalid: true });
      return;
    }

    this.resetPassword(password);
  }
  async resetPassword(password: string) {
    if (!this.id || !this.token) {
      return;
    }
    try {
      this.loading = true;
      const r = await this.userApi.resetPassword({
        id: this.id,
        token: this.token,
        password,
      });
      if (r.status === ApiStatus.ErrUserEmailTokenNotFound) {
        this.messageService.open('This link is invalid', 'OK');
      } else if (r.status == ApiStatus.ErrUserEmailTokenUsed) {
        this.messageService.open('This link is used', 'OK');
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.messageService.open('Password reset successfully', 'OK');
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
