import { Component } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { ShoppingApiService } from '../../../services/api/shopping-api.service';
import { ActivatedRoute } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '../../../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from '../../../services/message.service';
import { ShoppingOrderStatusComponent } from '../../../components/shopping/shopping-order-status/shopping-order-status.component';
import { MatDividerModule } from '@angular/material/divider';
import { ShoppingOrderItemListComponent } from '../../../components/shopping/shopping-order-item-list/shopping-order-item-list.component';
import { ShoppingPaymentPanelComponent } from '../../../components/shopping/shopping-payment-panel/shopping-payment-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { ApiStatus, UserOrderStatus } from '../../../services/api/status';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../../components/common/info-dialog/info-dialog.component';
@Component({
    selector: 'app-shopping-order-page',
    imports: [
        MatProgressSpinnerModule,
        MatListModule,
        MatRippleModule,
        DecimalPipe,
        ShoppingOrderStatusComponent,
        DatePipe,
        MatDividerModule,
        ShoppingOrderItemListComponent,
        ShoppingPaymentPanelComponent,
        MatButtonModule,
        MatDialogModule,
    ],
    templateUrl: './shopping-order-page.component.html',
    styleUrl: './shopping-order-page.component.scss'
})
export class ShoppingOrderPageComponent {
  constructor(
    private titleService: TitleService,
    private shoppingApi: ShoppingApiService,
    private route: ActivatedRoute,
    private platform: Platform,
    private clipboard: Clipboard,
    private messageSerivce: MessageService,
    private dialog: MatDialog
  ) {
    this.titleService.set('Order Details', this.route);

    this.route.params.subscribe((r) => {
      if (environment.isDevelopment && !this.platform.isBrowser) {
        return;
      }
      this.data = null;
      this.getUserOrder(r['id']);
    });
  }

  loading = false;
  data: any;
  async getUserOrder(id: string) {
    try {
      this.loading = true;
      const r = await this.shoppingApi.getUserOrder(id);
      this.data = r.data;
      this.processItems();
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  copyID() {
    this.clipboard.copy(this.data.id);
    this.messageSerivce.open('Order ID copied', 'OK');
  }

  groupedItems: any[] = [];
  processItems() {
    const groupedItems: any[] = [];
    for (const item of this.data.items) {
      let matched = false;
      for (const item0 of groupedItems) {
        if (item0.product_snapshot.no === item.product_snapshot.no) {
          matched = true;
          item0.extra = item0.extra || [];
          item0.extra.push(Object.assign({}, item));
          break;
        }
      }
      if (!matched) {
        groupedItems.push(Object.assign({ extra: [] }, item));
      }
    }
    this.groupedItems = groupedItems;
  }

  showStatus() {
    if (this.data.status === UserOrderStatus.UserOrderStatusPending) {
      this.messageSerivce.open('We will review your order soon.', 'OK');
    } else if (this.data.status === UserOrderStatus.UserOrderStatusApproved) {
      this.messageSerivce.open(
        'Your order is approved, please pay for it. Thank you.',
        'OK'
      );
    }
  }

  cancel() {
    this.dialog.open(InfoDialogComponent, {
      width: '480px',
      autoFocus: false,
      data: {
        title: 'Cancel Order',
        messages: ['Are you sure to cancel the order?'],
        actions: [
          {
            text: 'Yes',
            onClick: () => this.cancelUserOrder(),
          },
        ],
      },
    });
  }

  async cancelUserOrder() {
    try {
      const r = await this.shoppingApi.cancelUserOrder(this.data.id);
      if (r.status === ApiStatus.ErrUserOrderNotFound) {
        this.messageSerivce.open('Order not found', 'OK');
      } else if (r.status === ApiStatus.ErrUserOrderStatusInvalid) {
        this.messageSerivce.open('Order status is invalid', 'OK');
      } else if (r.status !== ApiStatus.OK) {
        this.messageSerivce.open('Unknown Error', 'OK');
      } else {
        this.data = Object.assign({}, this.data, r.data);
      }
    } catch (error) {}
  }
}
