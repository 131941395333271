import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TitleService } from '../../../services/title.service';
import { UserApiService } from '../../../services/api/user-api.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiStatus } from '../../../services/api/status';
import { AuthService } from '../../../services/auth.service';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-email-verification-page',
    imports: [
        MatButtonModule,
        MatIconModule,
        RouterModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './email-verification-page.component.html',
    styleUrl: './email-verification-page.component.scss'
})
export class EmailVerificationPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private titleService: TitleService,
    private userApi: UserApiService,
    public authService: AuthService,
    private router: Router,
    private platform: Platform
  ) {
    this.titleService.set('Email Verification');
  }

  ngOnInit(): void {
    const id = this.route.snapshot.queryParams['id'];
    const token = this.route.snapshot.queryParams['token'];
    this.verifyEmail(id, token);
  }

  loading = false;
  status: ApiStatus | null = null;
  async verifyEmail(id: string, token: string) {
    if (!id || !token) {
      this.router.navigate(['/'], { replaceUrl: true });
      return;
    }
    try {
      this.loading = true;
      const r = await this.userApi.verifyUserEmail({ id, token });
      this.status = r.status;
    } catch (error) {
    } finally {
      this.loading = false;
      if (environment.isDevelopment && !this.platform.isBrowser) {
        return;
      }
      this.authService.getSelf();
    }
  }
}
