import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { ShoppingOrderStatusComponent } from '../shopping-order-status/shopping-order-status.component';
import { ShoppingOrderItemPreviewComponent } from '../shopping-order-item-preview/shopping-order-item-preview.component';

@Component({
  selector: 'app-shopping-order-item',
  imports: [
    RouterModule,
    MatRippleModule,
    DatePipe,
    ShoppingOrderStatusComponent,
    ShoppingOrderItemPreviewComponent,
  ],
  templateUrl: './shopping-order-item.component.html',
  styleUrl: './shopping-order-item.component.scss',
})
export class ShoppingOrderItemComponent {
  @Input({ required: true }) data: any;
}
