import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserApiService } from '../../../services/api/user-api.service';
import { UserAddressItemComponent } from '../user-address-item/user-address-item.component';
import { NgClass } from '@angular/common';
import { UpdateUserAddressDialogComponent } from '../update-user-address-dialog/update-user-address-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { removeItemInArray } from '../../../utils/array';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
    selector: 'app-user-address-select-dialog',
    imports: [
        MatDialogModule,
        MatButtonModule,
        UserAddressItemComponent,
        NgClass,
        MatIconModule,
        RouterModule,
        MatProgressSpinnerModule,
        NzEmptyModule,
    ],
    templateUrl: './user-address-select-dialog.component.html',
    styleUrl: './user-address-select-dialog.component.scss'
})
export class UserAddressSelectDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<UserAddressSelectDialogComponent>,
    private userApi: UserApiService,
    private dialog: MatDialog
  ) {}

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  ngOnInit(): void {
    this.findUserAddresses();
  }

  items: any[] = [];
  loading = false;
  selected: any = null;
  async findUserAddresses() {
    try {
      this.loading = true;
      const r = await this.userApi.findUserAddresses();
      this.items = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  select() {
    this.close(this.selected);
  }
}
