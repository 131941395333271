import { Platform } from '@angular/cdk/platform';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { CustomReuseStrategy } from '../../route-reuse-strategy';
import { MessageService } from '../message.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private platform: Platform,
    @Optional() @Inject('REQUEST') private req: any,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.platform.isBrowser && this.req) {
      req = req.clone({
        withCredentials: true,
        setHeaders: {
          cookie: this.req?.headers.cookie ?? '',
        },
      });
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.messageService.open(`Client Error`, 'OK');
          return throwError(() => new Error('400'));
        } else if (err.status === 403) {
          return throwError(() => new Error('403'));
        } else if (err.status === 401) {
          CustomReuseStrategy.clear();
          this.router.navigate(['/login'], {
            replaceUrl: true,
            queryParams: { r: this.router.url },
          });
          this.dialog.closeAll();
          return throwError(() => new Error('401'));
        } else {
          this.messageService.open(`Server Error`, 'OK');
          return throwError(() => err);
        }
      })
    );
  }
}
