import { NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { ProductPricePipe } from '../../../pipes/product-price.pipe';
@Component({
    selector: 'app-home-product-item',
    imports: [
        NgOptimizedImage,
        FileurlPipe,
        MatCardModule,
        MatRippleModule,
        RouterModule,
        ProductPricePipe,
    ],
    templateUrl: './home-product-item.component.html',
    styleUrl: './home-product-item.component.scss'
})
export class HomeProductItemComponent {
  @Input({ required: true }) data: any;
}
