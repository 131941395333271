import { DecimalPipe, NgOptimizedImage } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { TitlecasePipe } from '../../../pipes/titlecase.pipe';

@Component({
    selector: 'app-product-priced-attr-value-item',
    imports: [NgOptimizedImage, FileurlPipe, TitlecasePipe, DecimalPipe],
    templateUrl: './product-priced-attr-value-item.component.html',
    styleUrl: './product-priced-attr-value-item.component.scss'
})
export class ProductPricedAttrValueItemComponent {
  @Input({ required: true }) data: any;

  @HostBinding('class.large')
  get isLarge() {
    return !!this.data.image;
  }
}
