import { Component, HostBinding } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-search-box',
  imports: [
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatRippleModule,
    MatAutocompleteModule,
  ],
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss',
})
export class SearchBoxComponent {
  @HostBinding('class.active')
  active = false;
  query = '';
  options: string[] = [];
  constructor(private router: Router) {}

  search() {
    this.router.navigate(['/shopping/products'], {
      queryParams: { q: this.query },
    });
    this.active = false;
    this.query = '';
  }

  onOptionSelected(e: any) {
    const value = e?.option?.value;
    if (!value) {
      return;
    }
    this.router.navigate(['/shopping/products'], { queryParams: { q: value } });
  }

  get filteredOptions() {
    const opts = [];
    for (const opt of this.options) {
      if (opt.startsWith(this.query)) {
        opts.push(opt);
      }
    }
    return opts;
  }
}
