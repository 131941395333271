import { Component } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DecimalPipe } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { UserAddressSelectComponent } from '../../user/user-address-select/user-address-select.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessageService } from '../../../services/message.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmOrderDialogComponent } from '../confirm-order-dialog/confirm-order-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShoppingApiService } from '../../../services/api/shopping-api.service';
import { Router } from '@angular/router';
import { ApiStatus } from '../../../services/api/status';

@Component({
    selector: 'app-shopping-cart-summary-panel',
    imports: [
        MatListModule,
        DecimalPipe,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        UserAddressSelectComponent,
        MatProgressSpinnerModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    templateUrl: './shopping-cart-summary-panel.component.html',
    styleUrl: './shopping-cart-summary-panel.component.scss'
})
export class ShoppingCartSummaryPanelComponent {
  constructor(
    public cartService: CartService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private shoppingApi: ShoppingApiService,
    private router: Router
  ) {}

  loading = false;
  addr: any = null;
  remark = '';
  createOrder() {
    if (this.loading) {
      return;
    }
    const totalQuantity = this.cartService.quantity();
    const totalPrice = this.cartService.price();
    if (totalPrice <= 0 || totalQuantity <= 0) {
      this.messageService.open('There is no valid product in your cart', 'OK');
      return;
    }
    this.dialog
      .open(ConfirmOrderDialogComponent, {
        width: '480px',
        autoFocus: false,
        data: {
          totalItemQuantity: totalQuantity,
          totalPrice: totalPrice,
          addr: this.addr,
          totalProductCount: this.cartService.groupedItems().length,
          remark: this.remark,
        },
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.createOrderFromCart();
        }
      });
  }

  async createOrderFromCart() {
    try {
      this.loading = true;
      const r = await this.shoppingApi.createUserOrderFromCart({
        addrID: this.addr.id,
        remark: this.remark,
      });
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.messageService.open('Thank you for your order!', 'OK');
        this.router.navigate(['/shopping/order/', r.data.id], {
          replaceUrl: true,
        });
      }
    } catch (error) {
    } finally {
      this.loading = false;
      this.cartService.findShoppingCartItems();
    }
  }
}
